import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useStyles } from './styles';
import { ApplicationFormPopupProps } from './types';

import ModalPopup from '../../Modal/ModalPopup';
import BannerPopup from '../../Banner/BannerPopup';
import Banner from '../../Preview/UserDashboard/Banner';

const ApplicationPopup: React.FC<ApplicationFormPopupProps> = ({ client_id, dip_app_type, setShowApplicationForm, name, description }) => {
    const classes = useStyles();
    const [showInformationPopup, setShowInformationPopup] = useState(false);

    const handleInformationIconClick = () => {
        if (showInformationPopup) {
            setShowInformationPopup(false);
        } else {
            setShowInformationPopup(true);
        }

    }

    const getDashboardItemIcon = () => {

        if (dip_app_type === 'client_app') {
            return '/images/icons/AppDashboard_Desktop.svg';
        } else if (dip_app_type === 'web') {
            return '/images/icons/AppDashboard_Web.svg';
        } else if (dip_app_type === 'mobile_app') {
            return '/images/icons/AppDashboard_Mobile.svg';
        }

        return '';
    }

    const itemIcon = getDashboardItemIcon();

    return (
        <Container className={classes.container}>
            {showInformationPopup && (
                <Grid container className={classes.bannerPopupContainer}>
                    <ModalPopup paperClassName={classes.bannerPopup}>
                        <BannerPopup bannerHeader={name} bannerBody={description} toggleBannerPopup={setShowInformationPopup} />
                    </ModalPopup>
                </Grid>
            )
            }
            {!showInformationPopup && (
                <>
                    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                        <div onClick={() => setShowApplicationForm(false)}>
                            <img src={'/images/Close.svg'} className={classes.close} alt="" />
                        </div>
                    </Grid>
                    <Grid container className={classes.bannerContainer}>
                        <Banner
                            key={name}
                            bannerClass={classes.banner}
                            bannerIconBorderClass={classes.bannerIconBorder}
                            bannerTextClass={classes.bannerText}
                            bannerIcon={itemIcon}
                            bannerIconHover={itemIcon.replace('.svg', '_hover.svg')}
                            bannerText={name || ''}
                            bannerInformationIconUrl={'/images/icons/Information_gray.svg'}
                            bannerInformationClick={() => handleInformationIconClick()}
                            handleItemDisplay={() => { return null }}
                            index={0}
                            itemType="used"
                            client_id={client_id}
                            showAsActive={true}
                        />
                    </Grid>
                </>
            )}

        </Container>
    )
}

export default ApplicationPopup;
