import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { Helmet } from "react-helmet";
import { useStyles } from './styles';
import { TitleProps } from './types';

const Title: React.FC<TitleProps> = ({ showTitleOnPage }) => {
    const title = 'My Danfoss Maintenance Dashboard';
    const classes = useStyles();

    return (
        <>
            <Helmet defaultTitle={title} htmlAttributes={{ lang: 'en', translate: 'no' }} />
            {showTitleOnPage &&
                <Grid container className={classes.titleContainer}>
                    <Grid item>
                        <h1>{title}</h1>
                    </Grid>
                </Grid>
            }
        </>
    );
};
export default Title;